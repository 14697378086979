import React, { useState, useEffect } from 'react';
import '../css/LearnMore.css';
import Services from './Services';
import { Link } from 'react-router-dom';
import { SignedIn, SignedOut, useClerk } from "@clerk/clerk-react";
import UserAgreement from './UserAgreenment';

const LearnMore: React.FC<{ onSignUpClick: () => void }> = ({ onSignUpClick }) => {
    const { openSignUp } = useClerk();
    const [showUserAgreement, setShowUserAgreement] = useState(false);
  
    const steps = [
      "Enter your business idea",
      "An advanced AI analyzes your concept and gathers relevant data",
      "We gather suggested products to kickstart your business",
      "We generate a customized business plan and strategy",
      "Review and refine your plan with our interactive tools",
      "Start building your dream business with confidence"
    ];
  
    const handleSignUp = () => {
        onSignUpClick();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  
    const handleUserAgreementSubmit = async (tosAccepted: boolean, privacyAccepted: boolean) => {
      setShowUserAgreement(false);
      
      if (tosAccepted && privacyAccepted) {
        try {
          await openSignUp();
        } catch (err) {
          console.error('Error opening sign up');
        }
      }
    };
  
    const handleUserAgreementCancel = () => {
      setShowUserAgreement(false);
    };
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      }, { threshold: 0.2 });
  
      document.querySelectorAll('.step').forEach((step) => {
        observer.observe(step);
      });
  
      return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const servicesObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show');
            }
          });
        }, { threshold: 0.2 });
      
        document.querySelectorAll('.service-column').forEach((column) => {
          servicesObserver.observe(column);
        });
      
        return () => servicesObserver.disconnect();
      }, []);

  return (
    <div className="learn-more-container">
      <section className="hero">
        <h1 className="learn-more-main-title">Make Your<br/><span className="highlight">Dreams a Reality!</span></h1>
        <p className="learn-more-intro">
          Get personalized plans and resources to launch your startup, project, or business today.
        </p>
        <p className="learn-more-cta">
          Start now—your journey begins here!
        </p>
        <div className="hero-buttons">
          <button className="hero-button signup-button" onClick={handleSignUp}>Sign Up</button>
          <Link to="/about-us" className="hero-button about-button">About Us</Link>
        </div>
      </section>

      <section className="how-it-works">
        <h2 className="section-title">How It Works</h2>
        <div className="steps-container">
          {steps.map((step, index) => (
            <div key={index} className="step">
              <div className="step-content">
                <div className="step-number">{index + 1}</div>
                <p>{step}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Services />

      <div className="cta-container">
        <SignedIn>
          <div className="upgrade-button-wrapper">
            <Link to="/membership" className="upgrade-button">Upgrade to Premium</Link>
          </div>
        </SignedIn>
        <SignedOut>
          <div className="hero-buttons">
            <button className="hero-button signup-button" onClick={handleSignUp}>Sign Up</button>
          </div>
        </SignedOut>
      </div>

      {showUserAgreement && (
        <UserAgreement
          onSubmit={handleUserAgreementSubmit}
          onCancel={handleUserAgreementCancel}
        />
      )}
    </div>
  );
};

export default LearnMore;