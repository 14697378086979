import React from 'react';

interface FeatureCardProps {
  title: string;
  description: string;
  ctaText: string;
  onClick: () => void;
  imageSrc?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, ctaText, onClick, imageSrc }) => {
  console.log('Image source:', imageSrc); // Add this line
  return (
    <div className="feature-card">
      <div className="feature-card-image">
        {imageSrc ? (
          <img src={imageSrc} alt={title} />
        ) : (
          <div className="image-placeholder"></div>
        )}
      </div>
      <h3 className="feature-card-title">{title}</h3>
      <p className="feature-card-description">{description}</p>
      <button className="submit-button1-preliminary" onClick={onClick}>{ctaText}</button>
    </div>
  );
};

export default FeatureCard;