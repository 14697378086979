import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '@clerk/clerk-react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons';
import { getStepData } from '../../utils/localStorageUtils';
import '../../css/BusinessPlanSummary.css';
import '../../css/steps/Market.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { motion } from 'framer-motion';
import config from '../../config';
import LoadingPopup from '../LoadingPopup';
import SavePlanModal from '../SavePlanModal';

const API_BASE_URL = config.API_BASE_URL;

interface MarketTrendsData {
    economic_factors: Record<string, Record<string, number>>;
    industry_growth: {
        past_five_years: string;
        next_five_years_projection: string;
    };
    pricing_trends: {
        average_pricing: Record<string, number>;
        future_pricing_projections: Record<string, number>;
    };
    marketing_and_advertising: {
        channels: Record<string, number>;
    };
    consumer_behavior: {
        preferences: {
            age_group: Record<string, number>;
        };
    };
    source_links: Record<string, string>;
}

interface BusinessPlanSummaryProps {
    businessName: string;
    location: string;
    missionStatement: string;
    uniqueSellingPoint: string;
    targetDemographic: string;
    competitors: Array<{ name: string; url: string }>;
    marketTrends: MarketTrendsData | null;
    businessIdea: string;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const BusinessPlanSummary: React.FC = () => {
    const [businessPlan, setBusinessPlan] = useState<BusinessPlanSummaryProps | null>(null);
    const [indepthBusinessPlan, setIndepthBusinessPlan] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userInput, setUserInput] = useState('');
    const { session } = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        const businessOverview = getStepData('businessOverview');
        const targetMarket = getStepData('targetMarket');
        const businessIdea = localStorage.getItem('businessIdea');
        const marketTrends = JSON.parse(localStorage.getItem('marketTrends') || 'null');

        if (businessOverview && targetMarket && marketTrends) {
            setBusinessPlan({
                ...businessOverview,
                ...targetMarket,
                marketTrends,
                businessIdea: businessIdea ? JSON.parse(businessIdea) : ''
            });
        }
    }, []);

    if (!businessPlan) {
        return <div>Loading business plan summary...</div>;
    }

    const renderChart = (type: string, data: Array<Record<string, unknown>>, title: string) => {
        return (
            <div className="chart-container">
                <h3>{title}</h3>
                <ResponsiveContainer width="100%" height={300}>
                    {type === 'line' ? (
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            {title === 'Pricing Trends' ? (
                                <>
                                    <Line type="monotone" dataKey="currentPrice" stroke={COLORS[0]} name="Current Price" />
                                    <Line type="monotone" dataKey="futurePrice" stroke={COLORS[1]} name="Future Price" />
                                </>
                            ) : (
                                Object.keys(data[0]).filter(key => key !== 'year').map((key, index) => (
                                    <Line key={key} type="monotone" dataKey={key} stroke={COLORS[index % COLORS.length]} />
                                ))
                            )}
                        </LineChart>
                    ) : type === 'pie' ? (
                        <PieChart>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    ) : (
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="value" fill="#8884d8" />
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </div>
        );
    };

    const generateIndepthBusinessPlan = async () => {
        setIsLoading(true);
        if (!session) {
            alert('No active session. Please log in.');
            return;
        }

        try {
            const token = await session.getToken();
            const response = await axios.post(`${API_BASE_URL}/api/ideas/generate-indepth-business-plan`, {
                businessIdea: businessPlan?.businessIdea,
                missionStatement: businessPlan?.missionStatement,
                targetMarket: businessPlan?.targetDemographic,
                location: businessPlan?.location,
                businessName: businessPlan?.businessName
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            setIndepthBusinessPlan(response.data.indepthBusinessPlan);
        } catch (error) {
            console.error('Error generating in-depth business plan');
        }
        setIsLoading(false);
    };

    const prepareChartData = (marketTrends: MarketTrendsData) => {
        const economicFactorsData = Object.keys(marketTrends.economic_factors.inflation).map(year => ({
            year,
            inflation: marketTrends.economic_factors.inflation[year],
            interest: marketTrends.economic_factors.interest_rates[year],
            unemployment: marketTrends.economic_factors.unemployment[year]
        }));

        const pricingTrendsData = Object.keys(marketTrends.pricing_trends.average_pricing).map(year => ({
            year,
            currentPrice: marketTrends.pricing_trends.average_pricing[year],
            futurePrice: marketTrends.pricing_trends.future_pricing_projections[year] || null
        })).sort((a, b) => Number(a.year) - Number(b.year));

        const marketingAdvertisingData = Object.entries(marketTrends.marketing_and_advertising.channels).map(([channel, value]) => ({ name: channel, value }));

        const consumerBehaviorData = Object.entries(marketTrends.consumer_behavior.preferences.age_group).map(([key, value]) => ({ name: `Age ${key}`, value }));

        return {
            economicFactorsData,
            pricingTrendsData,
            marketingAdvertisingData,
            consumerBehaviorData
        };
    };

    const chartData = businessPlan.marketTrends ? prepareChartData(businessPlan.marketTrends) : null;

    const savePlan = async (planName: string) => {
        try {
            const token = await session?.getToken();
            await axios.post(`${API_BASE_URL}/api/users/auth/save-service-plan`, {
                planName,
                businessName: businessPlan?.businessName,
                location: businessPlan?.location,
                missionStatement: businessPlan?.missionStatement,
                uniqueSellingPoint: businessPlan?.uniqueSellingPoint,
                targetDemographic: businessPlan?.targetDemographic,
                competitors: businessPlan?.competitors,
                marketTrends: businessPlan?.marketTrends,
                businessIdea: businessPlan?.businessIdea,
                indepthBusinessPlan
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            setShowSaveModal(false);
            alert('Service plan saved successfully!');
            navigate('/profile');
        } catch (error) {
            alert('Failed to save service plan. Please try again.');
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleBusinessPlanChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIndepthBusinessPlan(e.target.value);
    };

    const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
    };

    const handleUserInputSubmit = async (e?: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) => {
        if (e && 'key' in e && e.key !== 'Enter') {
            return;
        }
        e?.preventDefault();

        setIsLoading(true);
        try {
            if (!session) {
                throw new Error('No active session');
            }

            const token = await session.getToken();
            if (!token) {
                throw new Error('No token available');
            }

            const membershipType = session.user.publicMetadata.membershipType as string;

            const response = await axios.post(`${API_BASE_URL}/api/ideas/business-plan-update`, {
                businessPlan: indepthBusinessPlan,
                userInput,
                membershipType
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });

            const updatedPlan = response.data.updatedBusinessPlan;
            setIndepthBusinessPlan(updatedPlan);
            setUserInput('');
        } catch (error) {
            console.error('Failed to update business plan', );
            alert('Failed to update business plan. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatBusinessPlan = (text: string) => {
        const formattedText = text
            .replace(/\n/g, '<br />')
            .replace(/(\d+\.\s)/g, '<strong>$1</strong>')
            .replace(/-\s/g, '<br />&nbsp;&nbsp;&nbsp;&nbsp;- ');
    
        return formattedText;
    };

    const isPremiumUser = session?.user?.publicMetadata?.membershipType === 'premium';

    return (
        <div className="business-plan-summary">
            <div className="main-container">
                <div className="header">
                    <h2 className="business-name">{businessPlan.businessName}</h2>
                    <p className="location">{businessPlan.location}</p>
                </div>
                <p className="mission-statement">{businessPlan.missionStatement}</p>
            </div>

            <motion.div 
                className="main-container info-grid"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <motion.div 
                    className="info-item"
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                >
                    <h3 className="summary_section-header">Target Demographic</h3>
                    <p className="summary_section-content">{businessPlan.targetDemographic}</p>
                </motion.div>
                <motion.div 
                    className="info-item"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                >
                    <h3 className="summary_section-header">Unique Selling Point</h3>
                    <p className="summary_section-content">{businessPlan.uniqueSellingPoint}</p>
                </motion.div>
                <motion.div 
                    className="info-item"
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.6 }}
                >
                    <h3 className="summary_section-header">Competition Overview</h3>
                    <div className="name-options">
                        {businessPlan.competitors.map((competitor, index) => (
                            <button
                                key={index}
                                className="name-option"
                                onClick={() => window.open(competitor.url, '_blank')}
                            >
                                {competitor.name}
                            </button>
                        ))}
                    </div>
                </motion.div>
            </motion.div>

            <div className="main-container">
                <h2 className="section-header">Market Trends</h2>
                <div className="market-trends-grid">
                    {chartData && (
                        <>
                            <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.2 }}>
                                {renderChart('line', chartData.economicFactorsData, 'Economic Factors')}
                            </motion.div>
                            <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.6 }}>
                                {renderChart('line', chartData.pricingTrendsData, 'Pricing Trends')}
                            </motion.div>
                            <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.8 }}>
                                {renderChart('pie', chartData.marketingAdvertisingData, 'Marketing and Advertising Channels')}
                            </motion.div>
                            <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 1 }}>
                                {renderChart('pie', chartData.consumerBehaviorData, 'Consumer Behavior by Age Group')}
                            </motion.div>
                        </>
                    )}
                    <div className="chart-container resources-container">
                        <h3>Resources</h3>
                        <div className="source-links-disclaimer">Some information may be incorrect and generated by AI. Please verify information before using.</div>
                        <div className="resources-options">
                            {businessPlan.marketTrends && Object.entries(businessPlan.marketTrends.source_links).map(([name, url]) => (
                                <Link 
                                    key={name} 
                                    to={url} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="name-option"
                                >
                                    {name}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-container">
                <h2 className="section-header">In-Depth Business Plan</h2>
                {!indepthBusinessPlan && !isLoading && (
                    <button onClick={generateIndepthBusinessPlan} className="generate-plan-btn">
                        Generate In-Depth Business Plan
                    </button>
                )}
                {isLoading && <LoadingPopup message="Generating business plan..." />}
                {indepthBusinessPlan && (
                    <div className="business-plan">
                        {isEditing ? (
                            <textarea
                                value={indepthBusinessPlan}
                                onChange={handleBusinessPlanChange}
                                rows={10}
                                className="business-plan-textarea"
                                style={{ width: '100%', height: '100%' }}
                            />
                        ) : (
                            <div 
                                className="business-plan-text" 
                                dangerouslySetInnerHTML={{ __html: formatBusinessPlan(indepthBusinessPlan) }} 
                            />
                        )}
                        <FontAwesomeIcon 
                            icon={isEditing ? faSave : faEdit} 
                            onClick={handleEditToggle} 
                            className="edit-icon" 
                            style={{ color: 'blue', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }} 
                        />
                    </div>
                )}
                {indepthBusinessPlan && (
                    <div>
                        <div className="regen-business-plan-text">Have details you want to include in your business plan?</div>
                        <div className="business-plan-input-container">
                            <input 
                                type="text" 
                                value={userInput} 
                                onChange={handleUserInputChange} 
                                onKeyDown={handleUserInputSubmit} 
                                placeholder="Type your details here and hit Enter" 
                                className="business-plan-input" 
                            />
                            <button 
                                className="submit-business-plan-button" 
                                onClick={handleUserInputSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}
            </div>

            
            <div className="main-container">
                {isPremiumUser ? (
                    <button onClick={() => setShowSaveModal(true)} className="save-plan-btn">
                        <FontAwesomeIcon icon={faSave} /> Save Business Plan
                    </button>
                ) : (
                    <div className="upgrade-message">
                        Upgrade to premium to save your business plan
                    </div>
                )}
            </div>

            {showSaveModal && isPremiumUser && (
                <SavePlanModal
                    onSave={savePlan}
                    onCancel={() => setShowSaveModal(false)}
                />
            )}
        </div>
    );
};

export default BusinessPlanSummary;