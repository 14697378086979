import React, { useState, useEffect } from 'react';
import { useUser, useSession } from '@clerk/clerk-react';
import axios from 'axios';
import config from '../config';
import './css/Branding.css';

const API_BASE_URL = config.API_BASE_URL;

interface UserProfile {
  id: string;
  username: string;
  email: string;
  membershipType: string;
  subscriptionStatus: string;
}

interface IconSuggestion {
  name: string;
  svg: string;
}

const Branding: React.FC = () => {
  const { isLoaded: isUserLoaded, user } = useUser();
  const { isLoaded: isSessionLoaded, session } = useSession();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [borderRadius, setBorderRadius] = useState<number>(20);
  const [borderColor, setBorderColor] = useState<string>('#000000');
  const [isGradient, setIsGradient] = useState<boolean>(false);
  const [gradientColor1, setGradientColor1] = useState<string>('#000000');
  const [gradientColor2, setGradientColor2] = useState<string>('#ffffff');
  const [textColor, setTextColor] = useState<string>('#000000');
  const [text, setText] = useState<string>('');
  const [fontFamily, setFontFamily] = useState<string>('Arial, sans-serif');
  const [fontStyle, setFontStyle] = useState<string>('normal');
  const [fontWeight, setFontWeight] = useState<string>('normal');
  const [isTextGradient, setIsTextGradient] = useState<boolean>(false);

  const [borderType, setBorderType] = useState<'none' | 'border' | 'filled'>('none');
  const [textGradientColor1, setTextGradientColor1] = useState<string>('#000000');
  const [textGradientColor2, setTextGradientColor2] = useState<string>('#ffffff');

  const [icons, setIcons] = useState<IconSuggestion[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const [selectedIconIndex, setSelectedIconIndex] = useState<number | null>(null);
  const [iconPosition, ] = useState<'left' | 'right'>('left');

  const [svgString, setSvgString] = useState<string>('');

  useEffect(() => {
    const fetchProfile = async () => {
      if (!isUserLoaded || !isSessionLoaded || !user || !session) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const token = await session.getToken();
        const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
          headers: { 
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        });
        setProfile(response.data);
      } catch (error) {
        setError(`An error occurred: ${error instanceof Error ? error.message : 'Unknown error'}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [isUserLoaded, isSessionLoaded, user, session]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value.slice(0, 15); // Limit to 15 characters
    setText(newText);
  };

  const handleBorderTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newBorderType = e.target.value as 'none' | 'border' | 'filled';
    setBorderType(newBorderType);
    if (newBorderType === 'none') {
      setIsGradient(false);
    }
  };

  const generateSVG = (): string => {
    const padding = 20;
    const borderWidth = 4;
    const fontSize = 24; // Fixed font size
    const iconSize = 24; // Fixed icon size
    const iconTextSpacing = 10; // Spacing between icon and text

    // Create a temporary SVG to measure text dimensions
    const tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const tempText = document.createElementNS("http://www.w3.org/2000/svg", "text");
    tempText.setAttribute("font-family", fontFamily);
    tempText.setAttribute("font-size", `${fontSize}px`);
    tempText.setAttribute("font-style", fontStyle);
    tempText.setAttribute("font-weight", fontWeight);
    tempText.textContent = text || 'Sample Text'; // Use 'Sample Text' if no text is entered
    tempSvg.appendChild(tempText);
    document.body.appendChild(tempSvg);
    const textBBox = tempText.getBBox();
    document.body.removeChild(tempSvg);

    const textWidth = Math.max(textBBox.width, 100); // Minimum width of 100px
    const textHeight = Math.max(textBBox.height, fontSize); // Minimum height based on font size

    const contentWidth = selectedIcon ? textWidth + iconSize + iconTextSpacing : textWidth;
    const svgWidth = Math.max(contentWidth + 2 * (borderWidth + padding), 200); // Minimum width of 200px
    const svgHeight = Math.max(textHeight + 2 * (borderWidth + padding), iconSize + 2 * (borderWidth + padding), 100); // Minimum height of 100px

    // Calculate positions
    let textX = svgWidth / 2; // Default center position

    if (selectedIcon) {
      const contentStartX = (svgWidth - contentWidth) / 2;
      if (iconPosition === 'left') {
        const iconX = contentStartX;
        textX = iconX + iconSize + iconTextSpacing + textWidth / 2;
      } else {
        textX = contentStartX + textWidth / 2;
      }
    }

    let fillColor = 'none';
    let strokeColor = 'none';
    let strokeWidth = 0;

    if (borderType === 'border') {
      strokeWidth = borderWidth;
      strokeColor = isGradient ? 'url(#borderGradient)' : borderColor;
    } else if (borderType === 'filled') {
      fillColor = isGradient ? 'url(#borderGradient)' : borderColor;
    }

    const svgContent = `
      <rect x="${borderWidth / 2}" y="${borderWidth / 2}" 
        width="${svgWidth - borderWidth}" height="${svgHeight - borderWidth}"
        rx="${borderRadius}" ry="${borderRadius}" 
        fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />
    `;

    const textFill = isTextGradient ? "url(#textGradient)" : textColor;

    const gradientDef = `
      <defs>
        ${(borderType !== 'none' && isGradient) ? `
          <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style="stop-color:${gradientColor1};stop-opacity:1" />
            <stop offset="100%" style="stop-color:${gradientColor2};stop-opacity:1" />
          </linearGradient>
        ` : ''}
        ${isTextGradient ? `
          <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style="stop-color:${textGradientColor1};stop-opacity:1" />
            <stop offset="100%" style="stop-color:${textGradientColor2};stop-opacity:1" />
          </linearGradient>
        ` : ''}
      </defs>
    `;

    const textElement = `
      <text x="${textX}" y="${svgHeight / 2}" dominant-baseline="middle" text-anchor="middle"
        font-family="${fontFamily}" font-size="${fontSize}px" fill="${textFill}"
        font-style="${fontStyle}" font-weight="${fontWeight}">
        ${text}
      </text>
    `;

    let iconElement = '';
    if (selectedIcon) {
      const iconX = iconPosition === 'left' ? (svgWidth - contentWidth) / 2 : textX + textWidth / 2 + iconTextSpacing;
      iconElement = `
        <g transform="translate(${iconX}, ${((svgHeight - iconSize) / 2) - 2})">
          <g fill="${textFill}">
            ${selectedIcon.replace(/fill="[^"]*"/g, '')}
          </g>
        </g>
      `;
    }

    return `
      <svg width="${svgWidth}" height="${svgHeight}" viewBox="0 0 ${svgWidth} ${svgHeight}" 
           xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
        ${gradientDef}
        ${svgContent}
        ${textElement}
        ${iconElement}
      </svg>
    `;
  };

  const handleGetIcons = async () => {
    if (text.trim() === '') {
      alert('Please enter a business name first.');
      return;
    }
    
    const description = prompt('Please enter a brief description of your business (5-50 characters):');
    
    if (description === null) {
      // User cancelled the prompt
      return;
    }
    
    if (description.length < 5 || description.length > 50) {
      alert('Please enter a description between 5 and 50 characters.');
      return;
    }
    
    await handleSubmitDescription(description);
  };

  const handleSubmitDescription = async (description: string) => {
    try {
      const token = await session?.getToken();
      const response = await axios.post(
        `${API_BASE_URL}/api/ideas/generate-branding-icons`,
        { businessName: text, description },
        { 
          headers: { 
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          },
        }
      );
      setIcons(response.data.icons);
    } catch (error) {
      alert('Failed to fetch icons. Please try again.');
    }
  };

  const handleIconSelect = (svg: string, index: number) => {
    // Remove any fill attributes from the SVG
    const cleanedSvg = svg.replace(/fill="[^"]*"/g, '');
    setSelectedIcon(cleanedSvg);
    setSelectedIconIndex(index);
  };

  useEffect(() => {
    setSvgString(generateSVG());
  }, [
    text, fontFamily, fontStyle, fontWeight, 
    borderType, borderRadius, borderColor, isGradient, 
    gradientColor1, gradientColor2, textColor, isTextGradient, 
    textGradientColor1, textGradientColor2, selectedIcon, iconPosition
  ]);

  const handleExportSVG = () => {
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = svgUrl;
    downloadLink.download = 'logo.svg';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleExportPNG = () => {
    const svgElement = document.querySelector('.svg-preview svg') as SVGSVGElement;
    if (svgElement) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const img = new Image();
      
      img.onload = () => {
        canvas.width = svgElement.width.baseVal.value;
        canvas.height = svgElement.height.baseVal.value;
        ctx?.drawImage(img, 0, 0);
        const pngUrl = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'logo.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!profile) return <div>No profile data available</div>;

  const isPremium = profile.membershipType === 'premium';

  return (
    <div className="branding-container">
      <h1>Branding Tool</h1>
      
      <div className="branding-layout">
        <div className="branding-top-section">
          <div className="branding-form-group">
            <label htmlFor="branding-brand-text">Company Name</label>
            <input 
              id="branding-brand-text" 
              type="text" 
              value={text} 
              onChange={handleTextChange}
              maxLength={15}
            />
          </div>
          
          <div className="branding-form-group branding-checkbox">
            <input
              id="branding-use-text-gradient"
              type="checkbox"
              checked={isTextGradient}
              onChange={(e) => setIsTextGradient(e.target.checked)}
            />
            <label htmlFor="branding-use-text-gradient">Use Gradient for Text</label>
          </div>

          {isTextGradient ? (
            <div className="branding-form-group branding-color-picker">
              <label>Text Gradient Color</label>
              <div className="branding-gradient-colors">
                <input
                  type="color"
                  value={textGradientColor1}
                  onChange={(e) => setTextGradientColor1(e.target.value)}
                />
                <span>to</span>
                <input
                  type="color"
                  value={textGradientColor2}
                  onChange={(e) => setTextGradientColor2(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div className="branding-form-group branding-color-picker">
              <label htmlFor="branding-text-color">Text Color</label>
              <input
                id="branding-text-color"
                type="color"
                value={textColor}
                onChange={(e) => setTextColor(e.target.value)}
              />
            </div>
          )}

          {isPremium && text.trim() !== '' && (
            <div className="branding-icon-section">
              <button onClick={handleGetIcons}>Get Icons</button>
              {icons.length > 0 && (
                <div className="branding-icon-suggestions">
                  <h3>Suggested Icons:</h3>
                  <div className="branding-icon-list">
                    {icons.map((icon, index) => (
                      <div 
                        key={index} 
                        className={`branding-icon-item ${selectedIconIndex === index ? 'selected' : ''}`} 
                        onClick={() => handleIconSelect(icon.svg, index)}
                      >
                        <div dangerouslySetInnerHTML={{ __html: icon.svg }} />
                        {selectedIconIndex === index && (
                          <div className="branding-checkmark-1">✓</div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        
        <div className="branding-svg-preview-container">
          <div 
            className="branding-svg-preview" 
            dangerouslySetInnerHTML={{ __html: svgString }} 
          />
        </div>
        
        <div className="branding-bottom-section">
          <div className="branding-form-group">
            <label className="branding-label-text-1" htmlFor="branding-border-type">Border Type</label>
            <select
              id="branding-border-type"
              value={borderType}
              onChange={handleBorderTypeChange}
            >
              <option value="none">None</option>
              <option value="border">Border</option>
              <option value="filled">Filled</option>
            </select>
          </div>

          {borderType !== 'none' && (
            <>
              <div className="branding-form-group branding-checkbox">
                <input
                  id="branding-use-gradient"
                  type="checkbox"
                  checked={isGradient}
                  onChange={(e) => setIsGradient(e.target.checked)}
                />
                <label className="branding-label-text-1" htmlFor="branding-use-gradient">
                  Use Gradient for {borderType === 'border' ? 'Border' : 'Fill'}
                </label>
              </div>

              {isGradient ? (
                <div className="branding-form-group branding-color-picker">
                  <label>{borderType === 'border' ? 'Border' : 'Fill'} Gradient Color</label>
                  <div className="branding-gradient-colors">
                    <input
                      type="color"
                      value={gradientColor1}
                      onChange={(e) => setGradientColor1(e.target.value)}
                    />
                    <span>to</span>
                    <input
                      type="color"
                      value={gradientColor2}
                      onChange={(e) => setGradientColor2(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <div className="branding-form-group branding-color-picker">
                  <label htmlFor="branding-border-color">{borderType === 'border' ? 'Border' : 'Fill'} Color</label>
                  <input
                    id="branding-border-color"
                    type="color"
                    value={borderColor}
                    onChange={(e) => setBorderColor(e.target.value)}
                  />
                </div>
              )}
            </>
          )}

          <div className="branding-form-group">
            <label htmlFor="branding-border-radius">Border Roundness: {borderRadius}px</label>
            <input
              id="branding-border-radius"
              type="range"
              min="0"
              max="40"
              value={borderRadius}
              className="branding-slider"
              onChange={(e) => setBorderRadius(Number(e.target.value))}
            />
          </div>

          <div className="branding-form-group">
            <label htmlFor="branding-font-family">Font Family</label>
            <select
              id="branding-font-family"
              value={fontFamily}
              onChange={(e) => setFontFamily(e.target.value)}
            >
              <option value="Arial, sans-serif">Arial</option>
              <option value="'Times New Roman', serif">Times New Roman</option>
              <option value="'Courier New', monospace">Courier New</option>
              <option value="'Georgia', serif">Georgia</option>
              <option value="'Verdana', sans-serif">Verdana</option>
            </select>
          </div>
          <div className="branding-form-group">
            <label htmlFor="branding-font-style">Font Style</label>
            <select
              id="branding-font-style"
              value={fontStyle}
              onChange={(e) => setFontStyle(e.target.value)}
            >
              <option value="normal">Normal</option>
              <option value="italic">Italic</option>
            </select>
          </div>
          <div className="branding-form-group">
            <label htmlFor="branding-font-weight">Font Weight</label>
            <select
              id="branding-font-weight"
              value={fontWeight}
              onChange={(e) => setFontWeight(e.target.value)}
            >
              <option value="normal">Normal</option>
              <option value="bold">Bold</option>
            </select>
          </div>
        </div>
      </div>
      
      <div className="branding-button-group">
        <button onClick={handleExportSVG}>Export as SVG</button>
        {isPremium && (
          <button onClick={handleExportPNG}>Export as PNG</button>
        )}
      </div>
    </div>
  );
};

export default Branding;