import React, { useEffect, useRef } from 'react';
import '../css/Services.css';

const Services: React.FC = () => {
  const comingSoonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      },
      { threshold: 0.2 }
    );

    if (comingSoonRef.current) {
      observer.observe(comingSoonRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section className="services-container">
      <h2 className="section-title">Our Services</h2>
      <div className="services-comparison">
        <div className="service-column free">
          <h3>Free Plan</h3>
          <ul>
            <li>Submit three ideas per day</li>
            <li>Ad-supported experience</li>
            <li>Choose a tier for your business</li>
            <li>Generate suggested products for your plan</li>
          </ul>
        </div>
        <div className="service-column premium">
          <h3>Premium Plan</h3>
          <ul>
            <li>Ad-free experience</li>
            <li>Unlimited submissions per day</li>
            <li>Choose a specific budget</li>
            <li>Mention products you already have</li>
            <li>Get customized and tailored product suggestions</li>
            <li>Receive a customized business plan</li>
            <li>Update your plan based on specific ideas</li>
          </ul>
        </div>
      </div>
      <div className="coming-soon" ref={comingSoonRef}>
        <h3>Coming Soon</h3>
        <p>Premium members can submit an application to have their idea viewed by potential investors</p>
        <p>Premium members gain exclusive access to the Genius Forum</p>
      </div>
    </section>
  );
};

export default Services;